.ant-table-thead .ant-table-cell {
  background-color: #fff2e8 !important;
}

// .ant-menu-submenu-title:hover {
//   background: #fa8c1622 !important;
//   .ant-menu-submenu-arrow::before,
//   .ant-menu-submenu-arrow::after {
//   }
// }

.ant-menu-item-active {
  background: rgba(255, 132, 0, 0.09) !important;
}

.table-row-light {
  background-color: #ffffff;
}
.table-row-dark td {
  background-color: #f7f7f7 !important;
}

.table-row-error td {
  background-color: #ffc0c0 !important;
}
